import { defineStore } from 'pinia'

export const useFiltersStore = defineStore('filters', {
  state: () => {
    return {
      query: '',
      location: '',
      category: [],
      theme: [],
    }
  },
})
