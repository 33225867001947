// style-loader: Adds some css to the DOM by adding a <style> tag

// load the styles
var content = require("!!../../node_modules/@roots/bud-support/lib/style-loader/index.cjs??style!../../node_modules/@roots/bud-support/lib/css-loader/index.cjs??css!../../node_modules/postcss-loader/dist/cjs.js??postcss!../../node_modules/resolve-url-loader/index.js??resolveUrl!../../node_modules/sass-loader/dist/cjs.js??sass!./app.scss");
if(content.__esModule) content = content.default;
if(typeof content === 'string') content = [[module.id, content, '']];
if(content.locals) module.exports = content.locals;
// add the styles to the DOM
var add = require("!../../node_modules/vue-style-loader/lib/addStylesClient.js").default
var update = add("0f2a3e9b", content, false, {});
// Hot Module Replacement
if(module.hot) {
 // When the styles change, update the <style> tags
 if(!content.locals) {
   module.hot.accept("!!../../node_modules/@roots/bud-support/lib/style-loader/index.cjs??style!../../node_modules/@roots/bud-support/lib/css-loader/index.cjs??css!../../node_modules/postcss-loader/dist/cjs.js??postcss!../../node_modules/resolve-url-loader/index.js??resolveUrl!../../node_modules/sass-loader/dist/cjs.js??sass!./app.scss", function() {
     var newContent = require("!!../../node_modules/@roots/bud-support/lib/style-loader/index.cjs??style!../../node_modules/@roots/bud-support/lib/css-loader/index.cjs??css!../../node_modules/postcss-loader/dist/cjs.js??postcss!../../node_modules/resolve-url-loader/index.js??resolveUrl!../../node_modules/sass-loader/dist/cjs.js??sass!./app.scss");
     if(newContent.__esModule) newContent = newContent.default;
     if(typeof newContent === 'string') newContent = [[module.id, newContent, '']];
     update(newContent);
   });
 }
 // When the module is disposed, remove the <style> tags
 module.hot.dispose(function() { update(); });
}