import { createApp } from 'vue'
import { createPinia } from 'pinia'
import debounce from "lodash.debounce"

import AgendaList from './components/AgendaList.vue';
import { useFiltersStore } from './store/filters.js';

const pinia = createPinia()

let store = null;

export default () => {
  const app = createApp({
    components: {
      AgendaList,
    },
    setup() {
      store = useFiltersStore()
    },
    data() {
      let params = (new URL(document.location)).searchParams

      return {
        query: store.query,
        location: params.get('location') ?? '',
        category: params.get('category') ?? '',
        themes: store.theme,
      };
    },
    methods: {
      filterByKeyword: debounce((event) => {
        store.query = event.target.value
      }, 500),
      filterByLocation() {
        store.location = this.location
      },
      toggleTerm(event, type) {
        let existingTerm = store[type].find( item => event.target.value in item );
        if (existingTerm) {
          existingTerm[event.target.value] = !existingTerm[event.target.value]
        } else {
          store[type].push({ [ event.target.value ] : true })
        }
      },
    },
  })

  app.use(pinia)
  app.mount('#bindkrachtvhl-agenda')
}
