import { computed, ref, reactive, watch, onMounted } from 'vue';
  import { omitBy, isNil } from 'lodash';
  import { useFetch } from '@vueuse/core';
  import { useFiltersStore } from './../store/filters.js';

  
export default {
  __name: 'AgendaList',
  setup(__props, { expose }) {
  expose();

  /* global bindkrachtVHLConfig */
  const store = useFiltersStore();

  const events = ref([]);
  const isFetching = ref(false);
  const error = ref(null);
  const data = ref(null);

  const variables = reactive({
    categories: null,
    tags: null,
    location: null,
    search: null,
    organizer: null,
    page: 1,
  });

  const hasQueryParams = () => {
    const params = new URLSearchParams(window.location.search);
    return Array.from(params.entries()).length > 0;
  };

  const url = computed(() => {
    const _url = new URL(`${bindkrachtVHLConfig.rest_url}tribe/events/v1/events`);
    const params = omitBy(variables, isNil);
    _url.search = new URLSearchParams(params);
    _url.searchParams.set('ongoing', 1);
    return _url.toString();
  });

  const fetchData = async () => {
    isFetching.value = true;
    error.value = null;

    try {
      const response = await fetch(url.value);
      const result = await response.json();

      if (!result || !response.ok) {
        throw new Error('Failed to fetch events.');
      }

      if (store.load_more) {
        events.value = [...events.value, ...result.events];
        store.load_more = false;
      } else {
        events.value = result.events;
      }

      data.value = result;
    } catch (err) {
      error.value = err;
    } finally {
      isFetching.value = false;
    }
  };

  watch(
    () => ({ ...variables }),
    () => {
      if (!isFetching.value) {
        fetchData();
      }
    },
    { deep: true }
  );

  onMounted(() => {
    if (!hasQueryParams()) {
      fetchData();
    }
  });

  const loadMore = () => {
    if (data.value.next_rest_url) {
      store.load_more = true;
      variables.page = variables.page + 1;
      fetchData();
    }
  };

  const filterTerms = (terms = null) => {
    if (terms) {
      terms = terms
        .map((term) => {
          if (Object.values(term)[0] !== false) {
            return Object.keys(term)[0];
          }
        })
        .filter((n) => n);

      if (terms.length > 0) {
        return terms;
      }
    }
  };

  const setQueryParams = (state = {}) => {
    let params = new URL(document.location).searchParams;

    for (let prop in state) {
      let value = state[prop];

      if (value) {
        if (Array.isArray(value)) {
          if (value.length > 0) {
            value = filterTerms(value)
            if (value) {
              value = value.join(',');
            }
          } else {
            value = null;
          }
        }

        if (value) {
          if (params.has(prop)) {
            params.set(prop, value);
          } else {
            params.append(prop, value);
          }
        } else if (params.has(prop)) {
          params.delete(prop);
        }
      }

      if (!value) {
        params.delete(prop);
      }
    }

    if (Array.from(params).length) {
      history.replaceState(null, null, `?${params.toString()}`);
    } else {
      history.replaceState(null, null, '/');
    }
  };

  store.$subscribe((mutation, state) => {
    setQueryParams(state)

    if (state.query) {
      variables.search = state.query;
      variables.page = null;
    } else {
      variables.search = null;
    }

    variables.location = state.location ?? null;

    variables.organizer = state.vendor ?? null;

    if (state.theme && state.theme.length) {
      variables.tags = filterTerms(state.theme);
    }

    if (state.category && state.category.length) {
      variables.categories = filterTerms(state.category);
    }
  });

  const params = new URLSearchParams(window.location.search);
  params.forEach((value, key) => {
    if (store[key] !== null && store[key] !== undefined) {
      if (['category', 'theme'].includes(key)) {
        let terms = value.split(',');
        value = [];

        terms.forEach((term) => {
          value.push({ [term]: true });
        });
      }
      store[key] = value;
    }
  });

const __returned__ = { store, events, isFetching, error, data, variables, hasQueryParams, url, fetchData, loadMore, filterTerms, setQueryParams, params, computed, ref, reactive, watch, onMounted, get omitBy() { return omitBy }, get isNil() { return isNil }, get useFetch() { return useFetch }, get useFiltersStore() { return useFiltersStore } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

}