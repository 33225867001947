import domReady from '@roots/sage/client/dom-ready';

import Agenda from './agenda/index.js';

/**
 * Application entrypoint
 */
domReady(async () => {
  if (document.querySelector('#bindkrachtvhl-agenda')) {
    Agenda();
  }

  let backLink = document.querySelector('.agenda__back');
  if (backLink) {
    backLink.addEventListener('click', (event) => {
      history.back();
      event.preventDefault();
    });
  }
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
